import * as React from 'react';
import { Link, navigate } from 'gatsby';
import backGround from '../images/404-bg.jpg';

import { Grid, Button, Typography } from '@material-ui/core';

import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';

// markup
const NotFoundPage = () => {
	return (
		<>
			<div
				style={{
					backgroundImage:
						`url(${backGround})`,
					minHeight: '80vh',
					backgroundSize: 'cover',
				}}>
				<Grid
					style={{
						// height: '100%',
						position: 'absolute',
						top: '30%',
						// left: '50%',
						// marginTop: '-50px',
						// marginLeft: '-50px',
					}}
					container
					direction='column'
					alignItems='center'
					justifyContent='center'>
					<Typography variant='h1' color='primary'>
						404
					</Typography>
					<Typography variant='h4' style={{ color: '#002D5C' }}>
						This page seems to have experienced a wipeout.
					</Typography>
					<Typography variant='h4' style={{ color: '#002D5C' }}>
						Hit the back button before it’s too late.
					</Typography>
					<Button
						size='large'
						color='primary'
						variant='contained'
						style={{ marginTop: '2rem' }}
						onClick={(e) => navigate(-1)}>
						Go Back
					</Button>
				</Grid>
			</div>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export default NotFoundPage;
